export const elevatorConsultingCompanyConstants = {
    GETALL_REQUEST: 'ECC_GETALL_REQUEST',
    GETALL_SUCCESS: 'ECC_GETALL_SUCCESS',
    GETALL_FAILURE: 'ECC_GETALL_FAILURE',
    
    GETONE_REQUEST: 'ECC_GETONE_REQUEST',
    GETONE_SUCCESS: 'ECC_GETONE_SUCCESS',
    GETONE_FAILURE: 'ECC_GETONE_FAILURE',

    UPDATE_SUCCESS: 'ECC_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ECC_UPDATE_FAILURE',

    NEW_SUCCESS: 'ECC_NEW_SUCCESS',
    NEW_FAILURE: 'ECC_NEW_FAILURE',

    DELETE_REQUEST: 'ECC_DELETE_REQUEST',
    DELETE_SUCCESS: 'ECC_DELETE_SUCCESS',
    DELETE_FAILURE: 'ECC_DELETE_FAILURE',
};