export const externalServiceToolsConstants = {   
    NEW_SERVICES_REPORT_FIELDS: 'NEW_SERVICES_REPORT_FIELDS',
    GET_CRITERIA_SUCCESS: 'GET_CRITERIA_SUCCESS',
    GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
    GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
    GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',
    REPORT_FIELDS: 'REPORT_FIELDS',

    GET_TESTER_CRITERIA_SUCCESS: 'GET_TESTER_CRITERIA_SUCCESS',
    GET_TESTER_CRITERIA_FAILURE: 'GET_TESTER_CRITERIA_FAILURE',
};
