import { externalServiceToolsConstants } from '../_constants';

export function externalServiceTools(state = {}, action) {
    switch (action.type) {

        case externalServiceToolsConstants.GET_REPORT_REQUEST:
            return { ...state, loading: true, report: [] }

        case externalServiceToolsConstants.GET_CRITERIA_SUCCESS:
            return { ...state, criteria: action?.criteria?.data }

        case externalServiceToolsConstants.GET_TESTER_CRITERIA_SUCCESS:
            return { ...state, criteria: action?.testerCriteria?.data }

        case externalServiceToolsConstants.GET_REPORT_SUCCESS:
            return { ...state, report: action?.report?.data, loading: false }

        case externalServiceToolsConstants.REPORT_FIELDS:
            return { ...state, fields: action.fields };
        
        case externalServiceToolsConstants.GET_REPORT_FAILURE:
            return {
                error: action.error,
                loading: false

            };

        default:
            return state
    }
}


