import { importDevicesConstants } from '../_constants';

export function importdevices(state = [], action) {
    switch (action.type) {
        case importDevicesConstants.UPLOAD_REQUEST:
            return { loading: true, devicesData: [] };
        case importDevicesConstants.UPLOAD_SUCCESS:
            return { ...state, devicesData: action.devicesData, loading: false };
        case importDevicesConstants.UPLOAD_FAILURE:
            return { error: action.message };

        case importDevicesConstants.RENDER_CLEAR:
            return { ...state, status: {} };
        case importDevicesConstants.RENDER_RESULTS:
            return { ...state, results: action.results, loading: false };

        case importDevicesConstants.SUBMISSION_REQUEST:
            return { ...state,  loading: true, status: {} };

        case importDevicesConstants.SUBMISSION_SUCCESS:
            return { ...state,  loading: false, status: action.devicesData };

        case importDevicesConstants.SUBMISSION_FAILURE:
            return {
                error: action.message
            };

        default:
            return state
    }
}