import { objectToFormData } from "./helpers";
import { authHeader } from "./auth-header";

export const preparePostRequest = (request, method = "POST") =>{
    const authHeaders = new Headers();
    authHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);

    const urlencoded = objectToFormData(request);

    const requestOptions = {
    method: method,
    headers: authHeaders,
    body: urlencoded,
    redirect: 'follow'
    };

    return requestOptions;

}

export const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
   
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}