import { importDevicesConstants } from '../_constants';
import { importDevicesService } from '../_services';
import { alertActions } from '.';

import store from '../store';

export const importDevicesActions = {
    upload,
    renderResult,
    submit
};

function upload(req, client_id) {
    return dispatch => {
        dispatch(request());
        importDevicesService.upload(req, client_id)
            .then(
                devicesData => {
                    if (!devicesData.error) {
                        dispatch(success(devicesData));
                    } else {
                        if (devicesData.error.includes('Unauthorized') || devicesData.error.includes('Failed to fetch') || devicesData.has_error) {
                             // window.location.href="/login";
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                    // if (error === 'Unauthorized.' || error == 'TypeError: Failed to fetch') {
                    //     window.location.href="/login";
                    // }
                }
            );
    };

    function request() { return { type: importDevicesConstants.UPLOAD_REQUEST } }
    function success(devicesData) { return { type: importDevicesConstants.UPLOAD_SUCCESS, devicesData } }
    function failure(error) { return { type: importDevicesConstants.UPLOAD_FAILURE, error } }
}


function submit(req, client_id, code) {
    return dispatch => {
        dispatch(request());
        importDevicesService.submit(req, client_id, code)
            .then(
                devicesData => {
                    if (!devicesData.error) {
                        dispatch(success(devicesData));
                    } 
                    else {
                        if (devicesData.error.includes('Unauthorized') || devicesData.error.includes('Failed to fetch')) {
                             window.location.href="/login";
                        }
                        else {
                            dispatch(failure(devicesData.error));
                            dispatch(alertActions.error(devicesData.error));
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                    if (error === 'Unauthorized.' || error == 'TypeError: Failed to fetch') {
                        // window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: importDevicesConstants.SUBMISSION_REQUEST } }
    function success(devicesData) { return { type: importDevicesConstants.SUBMISSION_SUCCESS, devicesData } }
    function failure(error) { return { type: importDevicesConstants.SUBMISSION_FAILURE, error } }
}


function renderResult(results) {
    return dispatch => {
        dispatch(clear())
        dispatch(success(results))
    };
    function clear() { return { type: importDevicesConstants.RENDER_CLEAR } }
    function success(results) { return { type: importDevicesConstants.RENDER_RESULTS, results } }
}