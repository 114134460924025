import { convertToDataTableCollection, setFields, depthOf } from 'src/_helpers';
import { adminToolsService } from 'src/_services';
import { clientReportConstants } from '../_constants';
import { alertActions } from './alert.actions';

export const adminToolsActions = {
    getClientReportCriteria,
    getClientReport
};

function getClientReportCriteria() {
    return dispatch => {
        adminToolsService.clientReportCriteria().then(criteria => {
            dispatch(success(criteria));
        })
        function success(criteria) { return { type: clientReportConstants.GET_CRITERIA_SUCCESS, criteria } }
        function failure(error) {
            return { type: clientReportConstants.GET_CRITERIA_FAILURE, error }
        }
    }
}

function getClientReport(req) {

    return dispatch => {
        dispatch(request());

        adminToolsService.clientReport(req).then(
            // this routine is used to find column headers
            report => {
               
                if (report) {
                    if (!report.error) {

                        if (Object.keys(report).length > 0) {

                            let clients = Object.keys(report[Object.keys(report)[0]]); // data => ['BentallGreenOak', Cadillac Fairview', 'Average']

                            // iterate and find how many levels. If 5 levels the contractors are included, 4 levels then no contractors. The last level is actual data so previous level is the column titles
                            let maxdepth = depthOf(report);
                            let fieldlevel = maxdepth - 2;  

                            let fields = '';
                            Object.keys(report).forEach(level0 => {
                                fieldlevel--;
                                if (level0 === 'data') {
                                    (fieldlevel-- > 0) && Object.keys(report[level0]).forEach(level1 => {  // level 1 is clients
                                        if (report[level0][level1].length == 0) {
                                            return;
                                        }                                    
                                        if (level1 == 'Average') { // don't do Average, it's not always available and if it is it's not like structured like a client
                                            return;
                                        }

                                        fields = Object.keys(report[level0][level1]);

                                        (fieldlevel > 0 && maxdepth == 5) && Object.keys(report[level0][level1]).forEach(level2 => { // go one step deeper if including contractors
                                            fields = Object.keys(report[level0][level1][level2]);
                                        });                                        
                                    });
                                }
                            });
                            
                            if (fields) {
                                fields.unshift('client');
                                fields.push('graph');
    
                                dispatch(setFields(fields, clientReportConstants.REPORT_FIELDS));
                            }
                        }
    
                    }
                }

                dispatch(success(report));
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }

        );
        function request() { return { type: clientReportConstants.GET_REPORT_REQUEST } }
        function success(report) { return { type: clientReportConstants.GET_REPORT_SUCCESS, report } }
        function failure(error) { return { type: clientReportConstants.GET_REPORT_FAILURE, error } }
    }
}
