import { setFields } from 'src/_helpers';
import { externalServiceToolsService } from 'src/_services';
import { externalServiceToolsConstants } from '../_constants';
import { alertActions } from './alert.actions';

export const newServiceToolsActions = {
    getCriteria,
    getReport,
    getTesterCriteria,
    getTesterReport,
};

function getCriteria() {

    return dispatch => {

        externalServiceToolsService.criteria().then(criteria => {
            dispatch(success(criteria));
        })
        function success(criteria) { return { type: externalServiceToolsConstants.GET_CRITERIA_SUCCESS, criteria } }
        function failure(error) {
            return { type: externalServiceToolsConstants.GET_CRITERIA_FAILURE, error }
        }
    }
}

function getReport(req) {
    return dispatch => {
        dispatch(request());

        externalServiceToolsService.report(req).then(
            report => {
                if (!report.error) {

                    if (Object.keys(report).length > 0) {
                        if (report.data) {
                            if (report.data.length > 0) { 
                                let fields = Object.keys(report.data[0]);
                                fields = fields.map(c => c === 'caller' ? 'contractor_name' : c);
                                dispatch(setFields(fields, externalServiceToolsConstants.REPORT_FIELDS));
                            }
                        }
                    }
                } 

                dispatch(success(report));
                localStorage.setItem('newServiceReportRequest', JSON.stringify(req));

            },
            error => {
                dispatch(failure(error));
                if (error === 'Unauthorized.') {
                    window.location.href="/login";
                }
                else {
                    dispatch(alertActions.error(error));
                }
            }
            
            );
            function request() { return { type: externalServiceToolsConstants.GET_REPORT_REQUEST } }
            function success(report) { return { type: externalServiceToolsConstants.GET_REPORT_SUCCESS, report } }
            function failure(error) { return { type: externalServiceToolsConstants.GET_REPORT_FAILURE, error } }
    }
}

function getTesterCriteria() {

    return dispatch => {

        externalServiceToolsService.testerCriteria().then(criteria => {
            dispatch(success(criteria));
        })
        function success(criteria) { return { type: externalServiceToolsConstants.GET_TESTER_CRITERIA_SUCCESS, criteria } }
        function failure(error) {
            return { type: externalServiceToolsConstants.GET_TESTER_CRITERIA_FAILURE, error }
        }
    }
}

function getTesterReport(req) {
    return dispatch => {
        dispatch(request());

        externalServiceToolsService.report(req).then(
            report => {
                if (!report.error) {

                    if (Object.keys(report).length > 0) {
                        if (report.data) {
                            if (report.data.length > 0) { 
                                let fields = Object.keys(report.data[0]);
                                fields = fields.map(c => c === 'caller' ? 'contractor_name' : c);
                                dispatch(setFields(fields, externalServiceToolsConstants.REPORT_FIELDS));
                            }
                        }
                    }
                } 

                dispatch(success(report));
                localStorage.setItem('newServiceReportRequest', JSON.stringify(req));

            },
            error => {
                dispatch(failure(error));
                if (error === 'Unauthorized.') {
                    window.location.href="/login";
                }
                else {
                    dispatch(alertActions.error(error));
                }
            }
            
            );
            function request() { return { type: externalServiceToolsConstants.GET_REPORT_REQUEST } }
            function success(report) { return { type: externalServiceToolsConstants.GET_REPORT_SUCCESS, report } }
            function failure(error) { return { type: externalServiceToolsConstants.GET_REPORT_FAILURE, error } }
    }
}