import { documentConstants } from '../_constants/document.constants';

export function documentTools(state = {}, action) {
    switch (action.type) {

        case documentConstants.GET_CRITERIA_SUCCESS:
            return { ...state, documentCriteria: action?.criteria }

        case documentConstants.GET_DOCUMENT_SUCCESS:
            return { ...state, document: action?.document, loading: false }

        case documentConstants.RENDER_RESULTS:
            return { ...state, results: action.results, loading: false };

        case documentConstants.DOCUMENT_TABLE_FIELDS:
            return { ...state, documentFields: action.fields };
        
        case documentConstants.GET_DOCUMENT_FAILURE:
            return {
                error: action.error,
                loading: false
            };

        default:
            return state;
    }
}


/*
    GET_CRITERIA_SUCCESS: 'DOCUMENT_GET_CRITERIA_SUCCESS',
    GET_CRITERIA_FAILURE: 'DOCUMENT_GET_CRITERIA_FAILURE',
    GET_DOCUMENT_REQUEST: 'GET_DOCUMENT_REQUEST',
    GET_DOCUMENT_SUCCESS: 'GET_DOCUMENT_SUCCESS',
    GET_DOCUMENT_FAILURE: 'GET_DOCUMENT_FAILURE',
    DOCUMENT_FIELDS: 'DOCUMENT_TABLE_FIELDS'
*/
