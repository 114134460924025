export const clientUserConstants = {
    GETALL_REQUEST: 'CLIENTUSERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'CLIENTUSERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'CLIENTUSERS_GETALL_FAILURE',

    GETONE_REQUEST: 'CLIENTUSERS_GETONE_REQUEST',
    GETONE_SUCCESS: 'CLIENTUSERS_GETONE_SUCCESS',
    GETONE_FAILURE: 'CLIENTUSERS_GETONE_FAILURE',

    UPDATE_SUCCESS: 'CLIENTUSERS_UPDATE_CLIENT_SUCCESS',
    UPDATE_FAILURE: 'CLIENTUSERS_UPDATE_CLIENT_FAILURE',

    NEW_CLIENT_SUCCESS: 'CLIENTUSERS_NEW_CLIENT_SUCCESS',
    NEW_CLIENT_FAILURE: 'CLIENTUSERS_NEW_CLIENT_FAILURE',

    GET_ASSETS_SUCCESS: 'CLIENTUSERS_GET_ASSETS_SUCCESS',
    GET_ASSETS_FAILURE: 'CLIENTUSERS_GET_ASSETS_FAILURE',

    GET_USERFORM_SUCCESS: 'CLIENTUSERS_GET_USERFORM_SUCCESS',
    GET_USERFORM_FAILURE: 'CLIENTUSERS_GET_USERFORM_FAILURE',

    DELETE_REQUEST: 'CLIENTUSERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'CLIENTUSERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'CLIENTUSERS_DELETE_FAILURE',
};