export const chartColors = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(0,59,182)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
    lightBlue: "lightBlue",
    lightGreen: "lightGreen",
};

export const setGraphOptions = (label, graphOptions) => {
    switch (label) {
        case "Annualized Callback Rate":
            graphOptions.borderColor = chartColors.blue;
            graphOptions.backgroundColor = chartColors.blue;
            graphOptions.yAxisID = "y-axis-1";
            break;
        case "# Devices":
            graphOptions.borderColor = chartColors.purple;
            graphOptions.backgroundColor = chartColors.purple;
            graphOptions.yAxisID = "y-axis-1";
            break;
        case "Callback Rate":
            graphOptions.borderColor = chartColors.blue;
            graphOptions.backgroundColor = chartColors.blue;
            graphOptions.yAxisID = "y-axis-1";
            break;
        case "Uptime %":
            graphOptions.borderColor = chartColors.orange;
            graphOptions.backgroundColor = chartColors.orange;
            graphOptions.yAxisID = "y-axis-2";
            break;
        case "Uptime":
            graphOptions.borderColor = chartColors.orange;
            graphOptions.backgroundColor = chartColors.orange;
            graphOptions.yAxisID = "y-axis-2";
            break;
        case "Maintenance Completion %":
            graphOptions.borderColor = chartColors.purple;
            graphOptions.backgroundColor = chartColors.purple;
            graphOptions.yAxisID = "y-axis-2";
            break;
        case "# of calls accounted for":
            graphOptions.borderColor = chartColors.purple;
            graphOptions.backgroundColor = chartColors.purple;
            graphOptions.yAxisID = "y-axis-2";
            break;
        case "#Calls":
            graphOptions.borderColor = chartColors.red;
            graphOptions.backgroundColor = chartColors.red;
            graphOptions.yAxisID = "y-axis-1";
            break;
        case "#Urgent Calls":
            graphOptions.borderColor = chartColors.yellow;
            graphOptions.backgroundColor = chartColors.yellow;
            graphOptions.yAxisID = "y-axis-1";
            break;
        case "# of devices with data":
            graphOptions.borderColor = chartColors.yellow;
            graphOptions.backgroundColor = chartColors.yellow;
            graphOptions.yAxisID = "y-axis-1";
            break;
        case "Call Response Time":
            graphOptions.borderColor = chartColors.lightBlue;
            graphOptions.backgroundColor = chartColors.lightBlue;
            graphOptions.yAxisID = "y-axis-1";
            break;
        case "Average Response Time":
            graphOptions.borderColor = 'lime';
            graphOptions.backgroundColor = 'lime';
            graphOptions.yAxisID = "y-axis-1";
            break;
        case "# Devices":
            graphOptions.borderColor = chartColors.lightGreen;
            graphOptions.backgroundColor = chartColors.lightGreen;
            graphOptions.yAxisID = "y-axis-1";
            break;
        case "On Time Response":
            graphOptions.borderColor = chartColors.lightGreen;
            graphOptions.backgroundColor = chartColors.lightGreen;
            graphOptions.yAxisID = "y-axis-1";
            break;
    }

    return graphOptions;
};

export const generateGraphDataSet = (graphData, exclude = []) => {
    let orderedGraphData = {}
    Object.keys(graphData).sort(function (a, b) {
        return new Date(a) - new Date(b);
    }).forEach(function (key) {
        orderedGraphData[key] = graphData[key];
    })
    let labels = [];
    let dataSets = [];
    Object.keys(orderedGraphData).forEach((d) => {
        labels.push(d);
        let dataSet = {};
        let keys = Object.keys(graphData[d]);

        keys.forEach((k) => {
            if (exclude.indexOf(k) > -1) {
                const data = parseFloat(graphData[d][k]);
                let graphObject = { label: k, data: [data], fill: false, lineTension: 0 };

                graphObject = setGraphOptions(k, graphObject);

                if (dataSets.filter((f) => f.label === k).length === 0) {
                    dataSets.push(graphObject);
                } else {
                    const el = dataSets.filter((f) => f.label === k)[0];
                    el.data.push(data);
                }
            }
        });
    });

    return {
        labels: labels.sort((a, b) => new Date(a) - new Date(b)),
        datasets: dataSets,
    };
};

export const graphOptions = {};
graphOptions.scales = {};
graphOptions.tooltips = {
    enabled: true,
};
graphOptions.scales = {
    xAxes: [{
        display: true,
        scaleLabel: {
            display: false,
            labelString: 'Month'
        }
    }],
    yAxes: [{
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: 'right',
        id: 'y-axis-1',
        display: true,
        ticks: {
            // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
            suggestedMin: 0,

            // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
            suggestedMax: 10
        },
        scaleLabel: {
            display: false,
            labelString: 'ACR'
        },
    }, {
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: 'left',
        id: 'y-axis-2',
        ticks: {
            // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
            suggestedMin: 50,

            // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
            suggestedMax: 100
        },
        scaleLabel: {
            display: true,
            labelString: '%'
        },

        // grid line settings
        gridLines: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
        }
    }],
}
