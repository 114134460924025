import { clientReportConstants } from '../_constants';

export function adminTools(state = {}, action) {
    switch (action.type) {

        case clientReportConstants.GET_REPORT_REQUEST:
            return { ...state, loading: true, clientReport: [] }

        case clientReportConstants.GET_CRITERIA_SUCCESS:
            return { ...state, clientReportCriteria: action?.criteria }


        case clientReportConstants.GET_REPORT_SUCCESS:
            return { ...state, clientReport: action?.report, loading: false }

        case clientReportConstants.REPORT_FIELDS:
            return { ...state, clientReportFields: action.fields };
        
        case clientReportConstants.GET_REPORT_FAILURE:
            return {
                error: action.error,
                loading: false

            };

        default:
            return state
    }
}


