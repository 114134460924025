export const multilineChartColors = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(0,59,182)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
    lightBlue: "lightBlue",
    lightGreen: "lightGreen",
}

function formatDate(month, year) {
    const mm = parseInt(month);
    const months = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'Aug',
        9: 'Sept',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
    }
    
    return months[mm] + ' ' + year;
}

export const setGraphDataSet = (graphData) => {
    
    const labels = [];
    const acr = [];
    const calls = [];
    const eCalls = [];
    const devices = [];
    
    const graphOption = {
        maintainAspectRatio: false,
        borderColor: window.chartColors.blue,
        backgroundColor: window.chartColors.blue,
        yAxisID: "y-axis-1"
    }
    
    graphOption.scales = {};
    graphOption.tooltips = {
        enabled: true,
    };
    graphOption.scales = {
        xAxes: [{
            display: true,
            stacked: true,
            offset: false,
            gridLines: {
                offsetGridLines: false
            },
            scaleLabel: {
                display: true,
                labelString: 'Month'
            }
        }],
        yAxes: [{
            type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
            display: true,
            position: 'right',
            id: 'y-axis-1',
            display: true,
            ticks: {
                // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
                suggestedMin: 0,

                // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
                suggestedMax: 10
            },
            scaleLabel: {
                display: true,
                labelString: 'Calls'
            },
        }, {
            type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
            display: true,
            position: 'left',
            id: 'y-axis-2',
            ticks: {
                // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
                suggestedMin: 0,

                // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
                suggestedMax: 10
            },
            scaleLabel: {
                display: true,
                labelString: 'Average Annualized Callback Rate'
            },

            // grid line settings
            gridLines: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
            }
        }],
    }
    
    // grab each plot
    if (graphData[0].length > 0) {
        
        graphData[0].forEach((datapoint) => {
            // format the X-axis labels
            let date = '' + datapoint.month;
            let year = date.substr(0, 4);
            let month = date.substr(4, 2);            
            let formatteddate = formatDate(month, year);
            labels.push(formatteddate + ' (' + datapoint.devices + ')');

            acr.push(parseFloat(datapoint.acr));
            calls.push(datapoint.calls);
            eCalls.push(datapoint.eCalls);
        });
        
        // temp data
        
/*
        for(var i=0; i < 12; i++) {
            let num = Math.round(0 + Math.random() * 20);
            acr.push(num);
            let num2 = Math.round(0 + Math.random() * 100);
            eCalls.push(num2);
            num = Math.round(num2 + Math.random() * 100);
            calls.push(num);
        }
*/

        // create each data object, Callback Rate is line graph, Urgent/Total Calls is a stacked bar graph
        const dataset = [
            {
                type: "line",
                label: "Average Annualized Callback Rate",
                data: acr,
                borderColor: window.chartColors.blue,
                backgroundColor: window.chartColors.lightBlue,
                yAxisID: 'y-axis-2'
                
            },
            {
                type: "bar",
                label: "# Urgent Calls",
                data: eCalls,
                borderColor: window.chartColors.orange,
                backgroundColor: window.chartColors.orange,
                yAxisID: 'y-axis-1'
            },
            {
                type: "bar",
                label: "Total # of Calls",
                data: calls,
                borderColor: window.chartColors.red,
                backgroundColor: window.chartColors.red,
                yAxisID: 'y-axis-1'
            }
        ];
    
        return { labels: labels, datasets: dataset, graphOptions: graphOption };
    }
    return false;
}

/*
<CChart
 type="line" 
 data={{
   labels: ["January", "February", "March", "April", "May", "June", "July"],
   datasets: [
     {
        label: "My First dataset",
        backgroundColor: "rgba(220, 220, 220, 0.2)",
        borderColor: "rgba(220, 220, 220, 1)",
        pointBackgroundColor: "rgba(220, 220, 220, 1)",
        pointBorderColor: "#fff",
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
      },
      {
        label: "My Second dataset",
        backgroundColor: "rgba(151, 187, 205, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "rgba(151, 187, 205, 1)",
        pointBorderColor: "#fff",
        data: [50, 12, 28, 29, 7, 25, 12, 70, 60]
      },
    ],
  }}
/>

data {
    data [ 
        [
            0 {month: 202204, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            1 {month: 202205, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            2 {month: 202206, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            3 {month: 202207, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            4 {month: 202208, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            5 {month: 202209, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            6 {month: 202210, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            7 {month: 202211, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            8 {month: 202212, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            9 {month: 202301, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            10 {month: 202302, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
            11 {month: 202303, acr: "0.0", calls: 0, eCalls: 0, devices: 0}
        ]
    ]
}
    
Data:
[
	[
		{
			"month": 202204,
			"acr": "2.0",
			"calls": 72,
			"eCalls": 11,
			"devices": 457
		},
		{
			"month": 202205,
			"acr": "1.9",
			"calls": 67,
			"eCalls": 12,
			"devices": 457
		}
    ]
]

    Object.keys(graphData).sort(function (a, b) {
        return new Date(a) - new Date(b);
    }).forEach(function (key) {
        orderedGraphData[key] = graphData[key];
    })
    let labels = [];
    let dataSets = [];
    Object.keys(orderedGraphData).forEach((d) => {
        labels.push(d);
        let dataSet = {};
        let keys = Object.keys(graphData[d]);

        keys.forEach((k) => {
            if (exclude.indexOf(k) > -1) {
                const data = parseFloat(graphData[d][k]);
                let graphObject = { label: k, data: [data], fill: false, lineTension: 0 };

                graphObject = setGraphOptions(k, graphObject);

                if (dataSets.filter((f) => f.label === k).length === 0) {
                    dataSets.push(graphObject);
                } else {
                    const el = dataSets.filter((f) => f.label === k)[0];
                    el.data.push(data);
                }
            }
        });
    });

    return {
        labels: labels.sort((a, b) => new Date(a) - new Date(b)),
        datasets: dataSets,
    };
*/
