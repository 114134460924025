
import { widgetsConstants } from '../_constants';

export function widgets(state = [], action) {
    switch (action.type) {

        case widgetsConstants.WIDGETS_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                widgets: []
            };

        case widgetsConstants.WIDGETS_FETCH_SUCCESS:
            return { ...state, widgets: action.widgets, loading: false };


        default:
            return state
    }
}