import config from '../config';
import { preparePostRequest, handleResponse } from '../_helpers';

export const devicesService = {
    upload,
    submit
};


function upload(request) {
    const requestOptions = preparePostRequest(request);

    return fetch(`${config.apiUrl}deviceData`, requestOptions).then(handleResponse);
}

function submit(request, key) {
    const requestOptions = preparePostRequest(request, 'PUT');

    return fetch(`${config.apiUrl}deviceData/${key}`, requestOptions).then(handleResponse);
}


