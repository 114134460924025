export const buildingOwnerConstants = {
    GETALL_REQUEST: 'BUILDINGOWNER_GETALL_REQUEST',
    GETALL_SUCCESS: 'BUILDINGOWNER_GETALL_SUCCESS',
    GETALL_FAILURE: 'BUILDINGOWNER_GETALL_FAILURE',
    
    GETONE_REQUEST: 'BUILDINGOWNER_GETONE_REQUEST',
    GETONE_SUCCESS: 'BUILDINGOWNER_GETONE_SUCCESS',
    GETONE_FAILURE: 'BUILDINGOWNER_GETONE_FAILURE',

    UPDATE_SUCCESS: 'BUILDINGOWNER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'BUILDINGOWNER_UPDATE_FAILURE',

    NEW_SUCCESS: 'BUILDINGOWNER_NEW_SUCCESS',
    NEW_FAILURE: 'BUILDINGOWNER_NEW_FAILURE',

    DELETE_REQUEST: 'BUILDINGOWNER_DELETE_REQUEST',
    DELETE_SUCCESS: 'BUILDINGOWNER_DELETE_SUCCESS',
    DELETE_FAILURE: 'BUILDINGOWNER_DELETE_FAILURE',
};