import config from '../config';
import { authHeader, handleResponse } from '../_helpers';

export const widgetsService = {
    getAll,
    deleteWidget
};

function getAll(clientId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}KJAStandardReporting/client/${clientId}/widget`, requestOptions).then(handleResponse);
}


function deleteWidget(clientId,widgetId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}KJAStandardReporting/client/${clientId}/widget/${widgetId}`, requestOptions).then(handleResponse);
}

