import config from '../config';
import { preparePostRequest, handleResponse, authHeader } from '../_helpers';

export const externalServiceToolsService = {
    criteria,
    report,
    testerCriteria,
    testerReport,
};


function criteria() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}webservice/criteria`, requestOptions).then(handleResponse);
}

function report(request, key) {
    const requestOptions = preparePostRequest(request);

    return fetch(`${config.apiUrl}webservice/report`, requestOptions).then(handleResponse);
}

async function testerCriteria() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${config.apiUrl}webservice/testerCriteria`, requestOptions);
    return handleResponse(response);
}

async function testerReport(request, key) {
    const requestOptions = preparePostRequest(request);

    return fetch(`${config.apiUrl}webservice/testerReport`, requestOptions).then(handleResponse);
}


